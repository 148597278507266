<template>
  <b-card no-body class="my-2">
    <b-card-header>
      Movimientos de Póliza
    </b-card-header>

    <b-card-body class="p-1">
      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <b-table
            :items="movimientos"
            :fields="fields"
            small
            responsive
            hover
            striped
            bordered
          >
            <template #cell(cuenta)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(fuente_financiamiento)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(proyecto)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(unidad_administrativa)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(partida)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(tipo_gasto)="data">
              <div class="text-danger" v-b-tooltip.hover :title="data.value.error" v-if="data.value.id===''">{{ data.value.clave }}</div>
              <div class="text-primary" v-b-tooltip.hover :title="data.value.nombre" v-else>{{ data.value.clave }}</div>
            </template>
            <template #cell(cargo)="data">
              {{ $formatNumber(data.value) }}
            </template>
            <template #cell(abono)="data">
              {{ $formatNumber(data.value) }}
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'PolizasRMSMovimientos',

  props: {
    polizaId: {
      required: true
    }
  },

  async mounted () {
    this.loading = true
    const res = await this.$store.dispatch('polizasSAModule/movimientos', {
      poliza_id: this.polizaId
    })
    this.movimientos = res.data
    this.loading = false
  },

  data () {
    return {
      fields: [
        { key: 'asiento', label: 'Asiento' },
        { key: 'cuenta', label: 'Cuenta' },
        { key: 'fuente_financiamiento', label: 'F. Fuente Financiamiento' },
        { key: 'proyecto', label: 'Proyecto' },
        { key: 'unidad_administrativa', label: 'Unidad A.' },
        { key: 'partida', label: 'Partida' },
        { key: 'tipo_gasto', label: 'Tipo Gasto' },
        { key: 'concepto', label: 'Concepto' },
        { key: 'cargo', label: 'Cargo' },
        { key: 'abono', label: 'Abono' }
      ],

      loading: true,

      movimientos: null
    }
  }
}
</script>
